@font-face {
  font-family: "Argesta Headline";
  src: local("Argesta"),
  url("/public/tipografias/ARGESTA/Argesta Headline.otf") format("opentype"), url("/public/tipografias/ARGESTA/Argesta Headline Italic.otf") format("opentype");
}

@font-face {
  font-family: "Gotham-Book";
  src: local("Gotham-Book"),
  url("/public/tipografias/GOTHAM/Gotham-Book.otf") format("opentype"), url("/public/tipografias/GOTHAM/Gotham-Bold.otf") format("opentype"), url("/public/tipografias/GOTHAM/Gotham-Medium.otf") format("opentype");
}

body{
  font-family: "Gotham-Book",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.fst-italic{
  font-family: "Argesta";
}

.item-content{
  margin-top: 50px;
}

.item-content h1 a {
  color: #333;
}

.item-content h1 a:hover {
  color: #938349;
}

/*
453a2d
*/

.item-content-menus .card .card-body a.btn-primary.card-link{
  background-color: #938349;
  border-color: #938349;
}

.item-content-menus .card .card-body a.btn-primary.card-link:hover{
  background-color: #453a2d;
  border-color: #453a2d;
}

.navbar{
  box-shadow: 0px 15px 15px -7px rgba(0,0,0,0.62);
  -webkit-box-shadow: 0px 15px 15px -7px rgba(0,0,0,0.62);
  -moz-box-shadow: 0px 15px 15px -7px rgba(0,0,0,0.62);
}

.navbar .nav-title{
  color: #7d782f;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.page-body{
  min-height: calc( 100vh - 440px );
}

.big-title{
  border-top: 1px solid #ccc;
  padding-top: 20px;

  text-align: center;
  text-transform: uppercase;
  color: #3f3c38;
}

.big-title-after{
  display: block;
  width: 100px;
  background-color: #868049;
  height: 3px;
  margin: 3px auto 30px;
}
.item-home-data{
  margin-bottom: 25px;

}
.item-home-data .card-container{
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;

  box-shadow: 0px 0px 10px 1px rgba(168,166,166,0.57);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(168,166,166,0.57);
  -moz-box-shadow: 0px 0px 10px 1px rgba(168,166,166,0.57);

  cursor: pointer;

  transition: all .75s;
}

.item-home-data .card-container:hover{
  border-color: #453a2d;

  box-shadow: 0px 0px 10px 1px rgba(69,58,45,0.85);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(69,58,45,0.85);
  -moz-box-shadow: 0px 0px 10px 1px rgba(69,58,45,0.85);
}

.cover_container{
  padding: 30px;
  min-height: 400px;

  background-color: #938349;
  margin-bottom: 20px;
}


.text-cover{
  font-size: 20px;

  color: #8d8888;
  text-align: center;
}

.home-sales-point .cover_container{
  min-height: 300px;
}

.home-sales-point .text-cover{
  font-size: 18px;
}

.home-sales-point .title-cover{
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #938349;
}
.item-home-data .card-container:hover .title-cover{
  text-decoration: underline;
}

.row.footer{
  background-color: #eee;
  margin-top: 60px;
  padding: 30px 0;

  box-shadow: 0px 0px 16px 4px rgba(69,58,45,0.44);
  -webkit-box-shadow: 0px 0px 16px 4px rgba(69,58,45,0.44);
  -moz-box-shadow: 0px 0px 16px 4px rgba(69,58,45,0.44);
}
.row.footer .col{
  font-size: 15px;
  color: rgba(147, 131, 73, 0.75);
}

.item-category-short{
  padding: 10px 0;

}
.item-category-short .item-category-title{
  color: #938349;
  text-transform: uppercase;
  font-weight: bold;
}
.item-category-short .item-category-text{
  color: #8d8888;
}

.item-category-short .item-category-div{
  width: 100px;
}

.item-category-short .btn.btn-outline-dark{
  color: #938349;
  border-color: #938349;
}

.item-category-short .btn.btn-outline-dark:hover{
  color: white;
  background-color: #938349;
}

.item-category-short .item-category-separator{
  --border-data: 1px 2px 5px 0px rgb(171, 171, 171);
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
  box-shadow: var( --border-data );
  -webkit-box-shadow: var( --border-data );
  -moz-box-shadow: var( --border-data );
}

.item-category-text p{
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .page-body{
    min-height: calc( 100vh - 480px );
  }
}
